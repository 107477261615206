<template>
  <div class="m-4">
    <h2>Terms and Conditions</h2>
    <p>Last updated: May 13 2022</p>

    <h5>1. Introduction</h5>
    <p>
      Welcome to Jukebox Tools! These Terms and Conditions govern your use of
      our website and services. By accessing our website, you agree to these
      Terms and Conditions in full.
    </p>

    <h5>2. Intellectual Property Rights</h5>
    <p>
      Unless otherwise stated, Jukebox Tools and/or its licensors own the
      intellectual property rights for all material on Jukebox Tools. All
      intellectual property rights are reserved.
    </p>

    <h5>3. Use License</h5>
    <p>
      Permission is granted to temporarily download one copy of the materials on
      Jukebox Tools' website for personal, non-commercial transitory viewing
      only.
    </p>

    <h5>4. User Responsibilities</h5>
    <p>
      Users are responsible for ensuring that their use of our website and
      services complies with all applicable laws and regulations.
    </p>

    <h5>5. Limitation of Liability</h5>
    <p>
      Jukebox Tools will not be liable for any damages that arise from using our
      website or services.
    </p>

    <h5>6. Revisions and Errata</h5>
    <p>
      The materials appearing on Jukebox Tools' website could include technical,
      typographical, or photographic errors. Jukebox Tools does not warrant that
      any of the materials on its website are accurate, complete, or current.
    </p>

    <h5>7. Links</h5>
    <p>
      Jukebox Tools has not reviewed all of the sites linked to its website and
      is not responsible for the contents of any such linked site.
    </p>

    <h5>8. Site Terms of Use Modifications</h5>
    <p>
      Jukebox Tools may revise these Terms and Conditions at any time without
      notice.
    </p>

    <h5>9. Governing Law</h5>
    <p>
      Any claim relating to Jukebox Tools' website shall be governed by the laws
      of the website owner's jurisdiction without regard to its conflict of law
      provisions.
    </p>

    <h5>10. Contact Information</h5>
    <p>
      If you have any questions about these Terms and Conditions, please contact
      us at <a href="mailto:info@jukebox.tools">info@jukebox.tools</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "terms",
};
</script>
